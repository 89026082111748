import Modal from '@/components/common/AppModal/Modal'
import { useAppSelector } from '@/hooks/redux'
import { setDappLoginModalVisible } from '@/redux/reducers/globalsSlice'
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useEffectOnlyOnUpdate } from '../hooks/useEffectOnlyOnUpdate'
import { UnlockPage } from "@multiversx/sdk-dapp/UI/pages/UnlockPage";

const LoginModal: React.FC = () => {
  const dispatch = useDispatch()

  const isLoggedIn = useGetIsLoggedIn()

  const dappLoginModalVisible = useAppSelector(
    state => state.globalsReducer.dappLoginModalVisible,
  )

  useEffectOnlyOnUpdate(() => {
    if (isLoggedIn) {
      dispatch(setDappLoginModalVisible(false))
    }
  }, [dispatch, isLoggedIn])

  const toggle = () => {
    dispatch(setDappLoginModalVisible(!dappLoginModalVisible))
  }

  return (
    <Modal isVisible={dappLoginModalVisible} setIsVisible={toggle} className='max-w-650'>
      <UnlockPage title={'Connect your wallet'} loginRoute={window.location.pathname} />
    </Modal>
  )
}

export default LoginModal
