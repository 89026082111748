import { sideBarItems } from "@/utils/router";
import Link from "next/link";
import { useRouter } from "next/router";
import favicon from "@/public/favicon.png";

// import ActiveLendingNavIcon from "@/public/lending-nav-icon.svg";
// import LendingNavIcon from "@/public/lending-nav-gray-icon.svg";

function Sidebar() {
  const router = useRouter();

  return (
    <>
      <div className="sidebar">
        <div className="brand-logo">
          <Link legacyBehavior={true} href="/">
            <a className="mini-logo">
              <img src={favicon.src} alt="" width="40" />
            </a>
          </Link>
        </div>
        <div className="menu">
          <ul>
            {sideBarItems.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    router.pathname == item.path
                      ? "active"
                      : router.pathname.startsWith("settings", 1) &&
                        item.path.startsWith("settings", 1)
                      ? "active"
                      : ""
                  }
                >
                  <Link legacyBehavior={true} href={item.path}>
                    <a>
                      <i className={item.icon} />
                      <span className="nav-text">{item.name}</span>
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
export default Sidebar;
