import { useUser } from "@/components/Providers";
import { AccountGame, ApiErrorResponse } from "@/types/api-response-types";
import { fetcherWithAuth } from "@/utils/api/api";
import useSWR, { KeyedMutator } from 'swr'

interface GetAccountResponse {
    data?: AccountGame;
    error?: any;
    refetch?: KeyedMutator<any>;
    isLoading?: boolean;
}

export function UseGetAccount(): GetAccountResponse {
    const { user } = useUser();
    const { data, error, mutate } = useSWR<AccountGame[], ApiErrorResponse>(
        '/api/users/account',
        user?.access_token ? url => fetcherWithAuth(url, user?.access_token) : null,
    );

    return {
        data: data && data.length > 0 ? data[0] : undefined,
        error: error,
        isLoading: !data,
        refetch: mutate
    }
}