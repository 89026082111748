import { useGetAccountInfo, useGetLoginInfo } from '@multiversx/sdk-dapp/hooks'
import { logout } from '@multiversx/sdk-dapp/utils'
import { useRouter } from 'next/router'
import { errorParse } from '../utils/errorParse'

type Logout = {
  callbackRoute?: string
  redirectFn?: (callbackRoute?: string) => void
}

export const useLogout = () => {
  const loginInfo = useGetLoginInfo()
  const accountInfo = useGetAccountInfo()
  const router = useRouter()

  const logoutProvider = async (params?: Logout) => {
    let callbackRoute: string | undefined
    let redirectFn: ((callbackRoute?: string) => void) | undefined

    if (params) {
      callbackRoute = params.callbackRoute ?? router.route
      redirectFn = params.redirectFn
    }
    try {
      await logout(callbackRoute, redirectFn, false)
      if (callbackRoute) {
        if (redirectFn) {
          redirectFn(callbackRoute)
        } else if (window) {
          // window.location.href = params?.callbackRoute;
          router.replace(callbackRoute)
        }
      }
    } catch (e) {
      const err = errorParse(e)
      console.error('error logging out', err)
    }
  }

  return {
    logoutProvider,
    pending: accountInfo.isAccountLoading,
    loggedIn: loginInfo.isLoggedIn,
    error: accountInfo.accountLoadingError,
  }
}
