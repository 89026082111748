import { useAppSelector } from '@/hooks/redux'
import { useElrondNetworkSync } from '@/wallet-integration/hooks/useElrondNetworkSync'
import { useTestLinkWallet } from '@/wallet-integration/hooks/useTestLinkWallet'
import { useGetLastSignedMessageSession } from '@multiversx/sdk-dapp/hooks/signMessage'
import { useRouter } from 'next/router'
import React from 'react'
import ErrorModal from '../common/ErrorModal/ErrorModal'
import Header from './Header'
import PageHead from './PageHead'
import PageTitle from './PageTitle'
import Sidebar from './sidebar'

interface LayoutProps {
  headTitle: string
  children: React.ReactNode
  pageTitle?: string
  pageTitleSub?: string
  pageClass?: string
  parent?: string
  child?: string
  sideComp?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({
  headTitle,
  children,
  pageTitle,
  pageTitleSub,
  pageClass,
  parent,
  child,
  sideComp,
}) => {
  useElrondNetworkSync()
  const signedMessageInfo = useGetLastSignedMessageSession()
  const user = useAppSelector(state => state.user)
  const { data } = useAppSelector(state => state.wallet)
  const { checkIfLinked } = useTestLinkWallet(signedMessageInfo)
  const router = useRouter()
  const { pathname } = router

  React.useEffect(() => {
    const { state } = window.history
    const isRedirected = state?.as?.includes('address=')
    // if(user.access_token) {
    //   console.log("trigger checkIfLinked");
    //   checkIfLinked().then(() => {
    //   });
    //   console.log("trigger checkIfLinked done");
    // }

    if (!data?.address && user.access_token) {
      if (isRedirected && pathname === '/settings-profile') {
        checkIfLinked().then(() => {
          const firstArg = state.as.split('?')[0]
          window.history.replaceState(state, '', firstArg)
        })
      } else {
        checkIfLinked(true).then(() => {})
      }
    }
  }, [user, router])

  return (
    <>
      <PageHead headTitle={`Verko - ${headTitle}`} />
      <div id='main-wrapper' className={pageClass}>
        {/* {!user.user && <Loader />} */}
        {user?.user_id && (
          <>
            <Header />
            <Sidebar />
            <div className='content-body'>
              <div className='container'>
                {pageTitle && (
                  <PageTitle
                    pageTitle={pageTitle}
                    pageTitleSub={pageTitleSub}
                    parent={parent}
                    child={child}
                    sideComp={sideComp}
                  />
                )}
                {children}
              </div>
            </div>
          </>
        )}
        <ErrorModal />
      </div>
    </>
  )
}

export default Layout
