import Close from '@/public/close.svg'
import cn from 'classnames'
import React, { memo } from 'react'

type Props = {
  children: React.ReactNode
  className?: string
  isVisible: boolean
  setIsVisible: (newValue: boolean) => void
  style?: {}
}
const Modal: React.FC<Props> = ({
  children,
  className,
  isVisible,
  setIsVisible,
  style,
}) => {
  return (
    <>
      {isVisible && (
        <div style={style} className='app-modal__overlay'>
          <div className={cn(['app-modal__content', className])}>
            <div className='pb-4'>
              <button
                className='app-modal__close-btn'
                style={{
                  backgroundColor: 'transparent',
                  padding: 0,
                  border: 'none',
                }}
                onClick={() => setIsVisible(false)}
              >
                <Close
                  className='app-modal__close-btn-icon'
                  viewBox='0 0 8 8'
                  height='24px'
                  width='24px'
                />
              </button>
            </div>

            {children}
          </div>
        </div>
      )}
    </>
  )
}
export default memo(Modal)
