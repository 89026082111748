type PageTitleProps = {
  pageTitle?: string;
  pageTitleSub?: string;
  pageGradientTitle?: string;
  parent?: string;
  child?: string;
  sideComp?: React.ReactNode;
};

const PageTitle = ({
  pageTitle,
  pageTitleSub,
  parent,
  child,
  pageGradientTitle,
  sideComp
}: PageTitleProps) => (
  <div className="page-title">
    <div className="row align-items-center justify-content-between">
      <div className="col-xl-6 col-6">
        <div className="page-title-content">
          <h3>{pageTitle}</h3>
          <p className="mb-2">{pageTitleSub}</p>
        </div>
      </div>
      {sideComp && (
        <div className="col-auto">
          <div className="breadcrumbs">{sideComp}</div>
        </div>
      )}
      {(parent || child) && (
        <div className="col-auto">
          <div className="breadcrumbs">
            <a href="#">{parent} </a>
            <span>
              <i className="ri-arrow-right-s-line" />
            </span>
            <a href="#">{child}</a>
          </div>
        </div>
      )}
    </div>
  </div>
);
export default PageTitle;
