import { RouteType } from '@/types/router-types'

export const sideBarItems: RouteType[] = [
  {
    name: 'Dashboard',
    path: '/',
    icon: 'ri-layout-grid-fill',
  },
  {
    name: 'My Characters',
    path: '/characters',
    icon: 'ri-bear-smile-line',
  },
  {
    name: 'My Assets',
    path: '/assets',
    icon: 'ri-game-line',
  },
  {
    name: 'Account',
    path: '/account',
    icon: 'ri-wallet-line',
  },
  {
    name: 'Staking',
    path: '/staking',
    icon: 'ri-ancient-gate-line',
    // icon: "lending-menu-icon"
  },
  {
    name: 'Settings',
    path: '/settings-profile',
    icon: 'ri-settings-3-line',
  },
]
