import { useAppSelector } from "@/hooks/redux";
import { clearErrorMessage } from "@/redux/reducers/errorMessageSlice";
import React from "react";
import { useDispatch } from "react-redux";
import Modal from "../AppModal/Modal";

interface ErrorModalProps {}

const ErrorModal: React.FC<ErrorModalProps> = ({}) => {
  const { isVisable, message, title } = useAppSelector((state) => state.errorMessage);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearErrorMessage());
  };

  return (
    <Modal className="error-modal" isVisible={!!isVisable} setIsVisible={handleClose}>
      <div className="error-modal__content">
        <h2 className="error-modal__content__title">{title}</h2>
        <p className="error-modal__content__message">{message}</p>
        <button
          onClick={handleClose}
          className="error-modal__content__btn btn btn-danger"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
