import { useAppSelector } from '@/hooks/redux'
import { useGetAccount } from '@multiversx/sdk-dapp/hooks'
import { useGetLastSignedMessageSession } from '@multiversx/sdk-dapp/hooks/signMessage'
import { useEffect, useState } from 'react'
import { useTestLinkWallet } from './useTestLinkWallet'

export const useElrondNetworkSync = () => {
  const [accountDone, setAccountDone] = useState(false)
  const [loginInfoDone, setLoginInfoDone] = useState(false)
  const signedMessageInfo = useGetLastSignedMessageSession()
  const account = useGetAccount()

  // NEW
  const { testLinkWallet } = useTestLinkWallet(signedMessageInfo)
  const wallet = useAppSelector(state => state.wallet)

  // Redux Done
  useEffect(() => {
    const accountStorage = localStorage.getItem('ext_account')
    const parsedStorage = accountStorage ? JSON.parse(accountStorage) : null

    if (!parsedStorage?.address) {
      return
    }

    setAccountDone(true)
  }, [])

  // Redux Done
  useEffect(() => {
    const loginInfoStorage = localStorage.getItem('ext_login')
    if (loginInfoStorage) {
      setLoginInfoDone(true)
    }
  }, [])

  const linkWallet = async () => {
    try {
      console.log('TX STAT', '2', signedMessageInfo)
      if (!wallet.isLinked && account.address) {
        await testLinkWallet({
          message: account.address,
          signature: signedMessageInfo ? signedMessageInfo?.signature : undefined,
        })
      }
      // if (!isLinked && accountSnap.address && loginInfoSnap.loginToken && loginInfoSnap.signature) {
      //     await link(accountSnap.address, loginInfoSnap.loginToken, loginInfoSnap.signature);
      // }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    linkWallet,
  }
}
