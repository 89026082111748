import { usePagination } from '@/hooks'
import { createContext, useContext } from 'react'

type ContextValue = { paginationController: ReturnType<typeof usePagination>} | null

export const txHistoryContext = createContext<ContextValue>(null)

export const useTxHistoryContext = () => {
    const context = useContext(txHistoryContext)

    if (!context) throw new TypeError('`useTxHistoryContext` should be used inside `txHistoryContext.Provider`')

    return context
}