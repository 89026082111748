import { useAppSelector } from '@/hooks/redux'
import { showErrorMessage } from '@/redux/reducers/errorMessageSlice'
import {
  setWallet,
  setWalletLoading,
  setWalletUnlinked,
} from '@/redux/reducers/walletSlice'
import { api } from '@/utils/api/api'
import { EToastID, EToastType, notify } from '@/utils/notificationsCenter'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import { useSignMessage } from '@multiversx/sdk-dapp/hooks/signMessage'
import { SignedSessionType } from '@multiversx/sdk-dapp/reduxStore/slices'
import { AxiosHeaders } from 'axios'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLogout } from './useLogout'

type SignedMessage = {
  address: string
  message: string
  signature: string
}

export const useTestLinkWallet = (signedInfo?: SignedSessionType) => {
  console.log('TX STAT', signedInfo)
  const url = '/api/wallet/external'
  const dispatch = useDispatch()
  const router = useRouter()
  const { data, isLoading } = useAppSelector(state => state.wallet)

  const { access_token } = useAppSelector(state => state.user)
  const { logoutProvider: logout } = useLogout()
  const accountInfo = useGetAccountInfo()

  const isLinked = useRef(false)
  const { signMessage } = useSignMessage()

  const checkIfLinked = async (triggerLoadingStates = false): Promise<boolean> => {
    try {
      if (access_token) {
        triggerLoadingStates && dispatch(setWalletLoading(true))
        const check = await (
          await fetch(url, {
            method: 'GET',
            headers: { Authorization: access_token },
          })
        ).json()
        if (check.address) {
          // we got address so we already linked
          dispatch(setWallet(check))
          return true
        }
      }
      return false
    } catch (e) {
      return false
    } finally {
      triggerLoadingStates && dispatch(setWalletLoading(false))
    }
  }

  const testLinkWallet = async ({
    message,
    signature,
  }: {
    message: string
    signature?: string
  }) => {
    //TODO: try catch
    try {
      if (access_token) {
        // dispatch isLoading to true

        // Check if wallet is linked already in our identity
        const isLinkedCheck = await checkIfLinked()
        if (isLinkedCheck) {
          // dispatch(setWalletLoading(false));
          return
        }

        dispatch(setWalletLoading(true))

        let signedMessageInformation = {
          address: accountInfo.address,
          message: signedInfo?.message,
          signature,
        }
        if (!signature) {
          // const convertedMessage = Buffer.from(message)
          const signedMessage = await signMessage({
            message,
            callbackRoute: window.location.href,
          })
          signedMessageInformation = { ...(signedMessage?.toJSON() as SignedMessage) }
        }
        // Api call to link wallet
        await api
          .setHeaders(new AxiosHeaders().set('Authorization', access_token))
          .post('/api/wallet/external/link', {
            address: signedMessageInformation.address,
            message: signedMessageInformation.message?.startsWith('0x')
              ? signedMessageInformation.message
              : `0x${signedMessageInformation.message}`,
            signature: signedMessageInformation.signature?.startsWith('0x')
              ? signedMessageInformation.signature
              : `0x${signedMessageInformation.signature}`,
          })

        console.log('MADE REQUEST')
        // Check if wallet is linked now in our identity
        const isLinkSuccess = await checkIfLinked()
        if (isLinkSuccess) {
          notify(
            EToastID.LINK_WALLET_SUCCESS,
            false,
            EToastType.SUCCESS,
            'Wallet Linked Successfully',
          )
        }
        console.log('checked wallet')
      }
    } catch (err) {
      console.error(err)
      // @ts-ignore
      const { error } = err.response?.data
      dispatch(setWalletUnlinked())
      notify(EToastID.LINK_WALLET_ERROR, false, EToastType.ERROR, error.data)
    }
  }

  const testUnlinkWallet = async () => {
    //TODO: try catch
    try {
      // dispatch isLoading to true
      dispatch(setWalletLoading(true))

      // Api call to unlink wallet
      await api
        // eslint-disable-next-line camelcase
        .setHeaders(new AxiosHeaders().set('Authorization', access_token))
        // @ts-ignore
        .post('/api/wallet/external/unlink', { address: data?.address })

      dispatch(setWalletUnlinked())
      // Call logout method
      await logout()
      notify(
        EToastID.LINK_WALLET_SUCCESS,
        false,
        EToastType.SUCCESS,
        'Wallet Unlinked Successfully',
      )
    } catch (err) {
      dispatch(
        showErrorMessage({
          message: (err as any).response?.data,
          title: 'Could not unlink wallet',
        }),
      )
    } finally {
      dispatch(setWalletLoading(false))
    }
  }

  return {
    testLinkWallet,
    testUnlinkWallet,
    checkIfLinked,
  }
}
