import { useAppSelector } from '@/hooks/redux'
import { UseGetAccount } from '@/hooks/useGetAccount'
import favicon from '@/public/favicon.png'
import { setDappLoginModalVisible } from '@/redux/reducers/globalsSlice'
import { updateStatus } from '@/redux/reducers/userSlice'
import { store } from '@/redux/store'
import authService from '@/service/auth-service'
import { AccountGameStatus } from '@/types/api-response-types'
import LoginModal from '@/wallet-integration/components/LoginModal'
import { useLogout } from '@/wallet-integration/hooks/useLogout'
import { shortenHash } from '@/wallet-integration/utils/shortenHash'
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

const ThemeSwitch = dynamic(() => import('../elements/ThemeSwitch'), {
  ssr: false,
})

const Header: React.FC = () => {
  const user = useAppSelector(state => state.user)

  const { data: userAccount, error, refetch } = UseGetAccount()

  const dispatch = useDispatch()
  const accountInfo = useGetAccountInfo()
  const { logoutProvider } = useLogout()
  const router = useRouter()

  useEffect(() => {
    if (userAccount) {
      store.dispatch(updateStatus(userAccount.status.toString()))
    }
    if (!refetch) return
    refetch()
  }, [userAccount])

  const [hoverLogout, setHoverLogout] = useState(false)

  const logoutFromWallet = async () => await logoutProvider({})

  return (
    <div className='header'>
      <div className='container'>
        <div className='row'>
          <div className='col-xxl-12'>
            <div className='header-content'>
              <div className='header-left'>
                <div className='brand-logo'>
                  <Link legacyBehavior={true} href='/'>
                    <a className='mini-logo'>
                      <img src={favicon.src} alt='' width='40' style={{ minWidth: 40 }} />
                    </a>
                  </Link>
                </div>
              </div>
              <div className='header-center-right'>
                {userAccount?.status === AccountGameStatus.web3Blocked && !error && (
                  <div className='header-center'>
                    <div className='status-banner'>
                      <span>Your account is blocked for any Web3 operations.</span>
                    </div>
                  </div>
                )}
                <div className='header-right'>
                  <div>
                    {accountInfo.address ? (
                      <div
                        className='external_wallet_connected'
                        onClick={logoutFromWallet}
                        onMouseEnter={() => {
                          setHoverLogout(true)
                        }}
                        onMouseLeave={() => {
                          setHoverLogout(false)
                        }}
                      >
                        <p>
                          {!hoverLogout ? shortenHash(accountInfo.address) : 'Logout'}
                        </p>
                      </div>
                    ) : (
                      <div
                        className='external_wallet_connected'
                        onClick={() => dispatch(setDappLoginModalVisible(true))}
                      >
                        <p>Connect Wallet</p>
                      </div>
                    )}
                  </div>
                  <LoginModal />
                  <ThemeSwitch />

                  <UncontrolledDropdown
                    tag='div'
                    className='nav-item dropdown notification'
                  ></UncontrolledDropdown>
                  {!user?.userName ? (
                    <button
                      type='button'
                      className='btn btn-primary me-3'
                      onClick={() => authService.login()}
                    >
                      Login
                    </button>
                  ) : (
                    <UncontrolledDropdown tag='div' className='dropdown profile_log'>
                      <DropdownToggle tag='div' data-toggle='dropdown'>
                        <div className='user icon-menu active'>
                          <span>
                            <img
                              src={user?.avatar as string}
                              alt=''
                              style={{
                                minWidth: '100%',
                                borderRadius: '100%',
                              }}
                            />
                          </span>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu right className='dropdown-menu'>
                        <div className='user-email'>
                          <div className='user'>
                            <span className='thumb'>
                              <img src={user?.avatar as string} alt='' />
                            </span>
                            <div className='user-info px-2'>
                              <h5>{user?.userName}</h5>
                              <span>{user?.email}</span>
                            </div>
                          </div>
                        </div>
                        <Link legacyBehavior={true} href='/settings-profile'>
                          <a className='dropdown-item'>
                            <span>
                              <i className='ri-settings-3-line' />
                            </span>
                            Settings
                          </a>
                        </Link>
                        <a
                          onClick={() => {
                            localStorage.removeItem('ext_login')
                            localStorage.removeItem('ext_account')
                            router.push('/logout-page')
                          }}
                          className='dropdown-item logout'
                        >
                          <i className='ri-logout-circle-line' />
                          Logout
                        </a>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
