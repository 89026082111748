import Head from "next/head";
import React from "react";
import favicon from "@/public/favicon.png";

type Props = {
  headTitle?: string;
};
const PageHead: React.FC<Props> = ({ headTitle }) => (
  <Head>
    <title>{headTitle}</title>
    <link rel="icon" href={favicon.src} />
  </Head>
);
export default PageHead;
